@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.question {
  button {
    opacity: 1;
    background-color: #df1c1b;
    border: none;
    color: #fff;
    border-radius: 7px;
    padding: 10px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    -webkit-transition: 0.5s;
    transition: 0.4s;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
    }
  }
  h4 {
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 20px;
    line-height: 30px;
    span {
      color: #fff;
      border-radius: 50%;
      padding: 5px 9px;
      background-color: #df1c1b;
    }
  }
}
.question_wrap {
  max-width: 600px;
  width: 100%;
}
.radio-buttons-container {
  align-items: center;
  gap: 24px;
}

.radio-button {
  display: block;
  padding-left: 30px;
  position: relative;
  cursor: pointer;

  &__input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__label {
    display: inline-block;
    padding: 10px 10px 10px 40px;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
    font-size: 16px;
    color: #000;
    border: 1.5px solid #38424f;
    max-width: 500px;
    width: 100%;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);

    &:hover {
      .radio-button__custom {
        transform: translateY(-50%) scale(1.2);
        border-color: #df1c1b;
        box-shadow: 0 0 10px rgba(#df1c1b, 0.8);
      }
      border: 1.5px solid #df1c1b;
    }
  }

  &__custom {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #555;
    margin-left: 15px;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  }
  &__input:checked + &__label {
    border: 1.5px solid #df1c1b;
    background-color: #fff;
  }
  &__input:checked + &__label .radio-button__custom {
    transform: translateY(-50%) scale(0.9);
    border: 5px solid #df1c1b;
    color: #df1c1b;
  }

  &__input:checked + &__label {
    color: #df1c1b;
  }
}
.line {
  margin: 20px 0;
  max-width: 600px;
  width: 100%;
  height: 1px;
  background-color: #bdbdbd;
}
video {
  width: 230px;
  position: fixed;
  bottom: 0;
  right: 0;
  audio {
    display: none;
  }
}

.level {
  margin-bottom: 15px;
  font-size: 32px;
  width: 200px;
  max-width: 100%;
}
form {
  p {
    margin-bottom: 30px;
  }
}
.step_wrap {
  display: flex;
  align-items: start;
  gap: 20px;
}
// StepperWithStyles.scss
.stepper_wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
}

.stepper_item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.8; /* Adjusted width */
  margin-right: 5px; /* Adjusted margin-right */

  @media (max-width: 768px) {
    font-size: 12px;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    z-index: 2;

    @media (max-width: 768px) {
      top: 15px;
    }
  }

  &::before {
    left: -50%;
  }

  &::after {
    left: 50%;
  }

  &.completed {
    .step_counter {
      background-color: #df1c1b;
      color: #fff;
    }

    &::after {
      border-bottom: 2px solid #df1c1b;
    }
  }

  &:first-child::before,
  &:last-child::after {
    content: none;
  }
}

.step_counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 768px) {
  .step_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    .level {
      order: 2;
    }
    .stepper_wrapper {
      order: 1;
      margin: 10px;
    }
  }
}
