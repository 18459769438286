.modal_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.ielts_wrapper {
  width: 350px;
  max-width: 90%; /* Максимальная ширина на мобильных устройствах */
  height: auto; /* Автоматическая высота */
  padding: 25px 24px;
  padding-top: 40px;
  position: fixed;
  background-color: #fff;
  top: 50%; /* Размещение по вертикали по центру */
  left: 50%; /* Размещение по горизонтали по центру */
  transform: translate(-50%, -50%); /* Центрирование */
  z-index: 10;
  box-shadow: 0 0 30px 1px #df1b1b36;
  border-radius: 8px;

  h1 {
    color: #000;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 25px;
  }

  h2 {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 10px;
  }

  input {
    width: 100%;
    height: 48px;
    padding-left: 20px;
    border: 1px solid rgba($color: #df1c1b, $alpha: 0.5);
    color: #01384d;
    border-radius: 7px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    outline: none;
    margin-bottom: 20px;
    margin-top: 10px;
    appearance: none;

    &:focus {
      border: 1px solid #df1c1b;
    }

    &:nth-child(1) {
      background-position: 20px;
    }

    &:nth-child(2) {
      background-position: 20px;
    }
  }

  .add_btn {
    width: 100%;
    height: 48px;
    cursor: pointer;
    color: #fff;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 7px;
    border: none;
    background: #df1c1b;
  }

  .close {
    position: absolute;
    top: 10px; /* Отступ сверху */
    right: 10px; /* Отступ справа */
    cursor: pointer;
    background-color: #df1c1b;
    width: 25px;
    height: 25px;
    border-radius: 8px;

    i {
      font-size: 18px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4px;
    }
  }
}
