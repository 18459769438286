.footer {
  padding: 30px 0;
  margin-top: 50px;
  background-color: #dbdcdb;
  .logo {
    img {
      width: 200px;
    }
  }
}

.footer_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_left {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
}
.copyright {
  color: gray;
  letter-spacing: 0.00938em;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  a {
    color: black;
    text-decoration: underline;
  }
}
.copyright2 {
  color: gray;
  letter-spacing: 0.00938em;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  display: none;
  a {
    color: black;
    text-decoration: underline;
  }
}
.social_medias {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .footer_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    .logo img {
      width: 150px;
    }
  }

  .footer_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
  }

  .copyright {
    display: none;
  }
  .copyright2 {
    display: block;
    margin-top: 15px;
  }
  .social_medias {
    display: flex;
    justify-content: center;
  }
}
