.result_wrap {
  background-color: #f98686;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(222, 13, 13, 0.1);
  text-align: center;
  h1 {
    margin: 0 auto;
    max-width: 800px;
  }

  h1 {
    font-size: 24px;
    color: #333;
  }
}
.sertifikat {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  width: fit-content;

  img {
    z-index: 1;
    max-width: 500px;
    width: 100%;

    height: auto;
  }
  h3 {
    position: absolute;
    top: 52%;
    z-index: 2;
    font-style: italic;
    font-weight: 400;
    font-size: 25px;
    @media (max-width: 767px) {
      top: 51.5%;
      font-size: 20px;
    }
  }
  h4 {
    position: absolute;
    top: 78.3%;
    left: 38.3%;
    z-index: 2;
    font-weight: bold;

    @media (min-width: 1024px) {
      top: 78.3%;
      left: 20.3%;
    }

    @media (max-width: 1023px) {
      top: 78.3%;
      left: 35%;
    }

    @media (max-width: 767px) {
      top: 77.3%;
      left: 18%;
    }
  }
}
.sertifikat + p {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 35px;
  margin-top: 36px;
  color: red;
}
.error {
  background: linear-gradient(135deg, #3d87e2, #0b5ea9);
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  color: #fff;

  .error_wrap {
    max-width: 800px;
    margin: 0 auto;
  }

  h1 {
    font-size: 36px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }

  p {
    font-size: 18px;
    margin-top: 20px;
  }

  a {
    display: inline-block;
    background-color: #ffaa00;
    color: #000;
    text-decoration: none;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #ffcc33;
    }
  }
}
.result_btns {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  flex-wrap: wrap;
  button {
    opacity: 1;
    background-color: #df1c1b;
    border: none;
    color: #fff;
    border-radius: 7px;
    padding: 10px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    -webkit-transition: 0.5s;
    transition: 0.4s;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
    }
  }
  .ielts {
    background-color: #999898;
  }
}
@media (max-width: 768px) {
  .result_btns {
    justify-content: center;
    gap: 20px;
  }
}
@media (max-width: 486px) {
  .result_btns {
    button {
      width: 100%;
    }
  }
  .home {
    .text {
      h1 {
        font-size: 25px;
        line-height: 40px;
      }
    }
  }
}
