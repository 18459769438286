.header {
  .logo {
    img {
      max-width: 200px;
      margin-top: 20px;
    }
  }
}

.header_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social_medias {
  display: flex;
  align-items: center;
  gap: 25px;
  .social_media {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #df1c1b;
    transition: all 0.4s ease;
    i {
      font-size: 22px;
      display: flex;
      justify-content: center;
      margin-top: 15px;
      color: #fff;
      transition: all 0.4s ease;
    }
    &:hover {
      i {
        color: #df1c1b;
      }
      background-color: #fff;
      box-shadow: 0px 0px 15px #df1c1b;
    }
  }
}
@media (max-width: 768px) {
  .social_medias {
    display: flex;
    align-items: center;
    gap: 10px;
    .social_media {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #df1c1b;
      transition: all 0.4s ease;
      i {
        font-size: 18px;
        display: flex;
        justify-content: center;
        margin-top: 9px;
        color: #fff;
        transition: all 0.4s ease;
      }
      &:hover {
        i {
          color: #fff;
        }
        background-color: #df1c1b;
        box-shadow: none;
      }
    }
  }
}
