.successful {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 320px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background: #4caf50;
  border-radius: 8px;
  box-shadow: 0px 0px 5px -3px #111;
  position: fixed;
  right: 10px;
  top: 10px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  animation: successful;
  animation-duration: 0.5s;
  z-index: 10;

  &__icon {
    width: 20px;
    height: 20px;
    font-size: 18px;
    transform: translateY(-2px);
    margin-right: 8px;
    color: #fff;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    color: #fff;
  }

  &__close {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: auto;

    path {
      fill: #fff;
    }
  }

  &.closed {
    opacity: 0;
    pointer-events: none;
  }
}
@keyframes successful {
  0% {
    right: -350px;
  }
  100% {
    right: 20px;
  }
}
