html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.fullscreen-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.content {
  position: relative;
  z-index: 1;
  /* to place the content above the video */
  /* Add any additional styling for your content */
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  text-align: center;
  margin-top: 50px;
  transform: translateY(-30px);

  .text {
    padding-top: 15px;
    max-width: 550px;
    margin: 0 auto;

    h1 {
      font-size: 33px;
      font-weight: 600;
      padding-bottom: 10px;
      line-height: 45px;
    }
  }
}

.home_form {
  padding-top: 25px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .form_wrap {
    max-width: 500px;
    /* Задаём максимальную ширину контейнера form_wrap */
    width: 100%;
    /* Чтобы занять всю доступную ширину родительского контейнера */
  }

  button {
    max-width: 500px;
    /* Задаём максимальную ширину input и button */
    width: 100%;
    /* Чтобы занять всю доступную ширину контейнера form_wrap */
    padding: 10px 20px;
    border: 2px solid rgba($color: #01384d, $alpha: 0.5);
    color: #01384d;
    border-radius: 7px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    outline: none;
    margin-top: 10px;

    &:focus {
      border: 2px solid #01384d;
    }
  }

  button {
    opacity: 1;
    background-color: #df1c1b;
    border: none;
    color: #fff;
    border-radius: 7px;
    padding: 10px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    -webkit-transition: 0.5s;
    transition: 0.4s;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
    }
  }
}

.form__group {
  position: relative;
  padding: 20px 0 0;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  .form__field {
    font-family: inherit;
    width: 100%;
    border: none;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 17px;
    color: #000;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;

    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ .form__label {
      font-size: 17px;
      cursor: text;
      top: 20px;
    }

    &:focus {
      padding-bottom: 6px;
      font-weight: 700;
      border-width: 3px;
      border-image: linear-gradient(to right, #ef4040, #df1c1b);
      border-image-slice: 1;
    }

    &:focus ~ .form__label {
      position: absolute;
      top: 0;
      display: block;
      transition: 0.2s;
      font-size: 17px;
      color: #df1c1b;
      font-weight: 700;
    }

    // Reset input
    &:required,
    &:invalid {
      box-shadow: none;
    }
  }

  .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 17px;
    color: #9b9b9b;
    pointer-events: none;
  }
}

.logo {
  img {
    width: 300px;
  }
}

.text_p {
  font-size: 16px;
}
.red {
  color: #df1c1b;
}
@media (max-width: 768px) {
  .home .text h1 {
    font-size: 28px;
  }

  .home_form {
    .form_wrap {
      max-width: 500px;
      width: 90%;
    }
  }

  .logo {
    img {
      width: 180px;
    }
  }
  .text_p {
    max-width: 400px;
    margin: 0 auto;
  }
}
