#chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.chart-center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-230%, -50%);
  /* Дополнительные стили для текста, если нужно */
}
@media (max-width: 480px) {
  .chart-center-text {
    transform: translate(-50%, -80%);
  }
}
