@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  @media only screen and (max-width: 600px) {
  }
}
.container {
  max-width: 1250px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  &--home {
    max-width: 1310px;
    padding: 0 15px;
    margin: 0 auto;
  }
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}
/* btn */
.btn {
  color: #df1c1b;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  padding: 16px 30px 14px 31px;
  transition: 0.4s;
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  &:hover {
    color: #fff;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
@media (max-width: 767px) {
  .home_form button {
    opacity: 1;
  }
}
